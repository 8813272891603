.contactUsFloatingContainer {
  justify-content: center;
}

.contactUsFloatingContainer form .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  padding-top: 20px;
}

.contactUsFloatingContainer input,
.contactUsFloatingContainer label,
.contactUsFloatingContainer button {
  padding: 10px;
  outline: none;
  margin: 15px;
}

.contactUsFloatingContainer textarea {
  margin: 15px;
}

.contactUsFloatingContainer p {
  font-size: 1.25rem;
}

.contactUsFloatingContainer .innerGrid label {
  position: absolute;
  color: #666;
  right: 15px;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  bottom: 25px;
  text-align: right;
  transition: all 0.2s ease;
}

.contactUsFloatingContainer .innerGridEn label {
  position: absolute;
  color: #666;
  left: 15px;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  bottom: 25px;
  text-align: left;
  transition: all 0.2s ease;
}

.contactUsFloatingContainer form button {
  width: -webkit-fill-available;
  display: block;
  cursor: pointer;
  color: white;
  font-size: 1.2rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  transition: background 0.4s ease;
  background: #a97a4f;
}

.contactUsFloatingContainer form button:hover {
  background: #bb9978;
}

.contactUsFloatingContainer input,
.contactUsFloatingContainer textarea {
  outline: none;
  resize: none;
  border: none;
  border-bottom: 1px #c2b59b solid;
  width: 100%;
  transition: border-color 0.5s ease;
}

.contactUsFloatingContainer input:focus + label,
.contactUsFloatingContainer input[required]:valid + label {
  transform: translateY(-20px);
}

.contactUsFloatingContainer textarea:valid + label,
.contactUsFloatingContainer textarea:focus + label {
  transform: translateY(-20px);
}

.contactUsFloatingContainer label {
  pointer-events: none;
}

.contactUsFloatingContainer p {
  max-width: 100% !important;
}

.contactUsFloatingContainer input[required]:valid,
.contactUsFloatingContainer textarea:valid {
  border-color: #a97a4f;
}

.contactUsFloatingContainer input:not(:placeholder-shown):valid {
  border-color: #a97a4f;
}

.contactcontactUsFloatingContainerForm
  input:not(:placeholder-shown):valid
  + label {
  transform: translateY(-20px);
}

.contactUsFloatingContainer input:not(:placeholder-shown):invalid + label {
  transform: translateY(-20px);
}
.contactUsFloatingContainer input:not(:placeholder-shown):invalid {
  border-color: red;
}

.contactUsFloatingContainer form .innerGrid {
  position: relative;
  padding-left: 50px;
}

.contactUsFloatingContainer form .innerGridEn {
  position: relative;
  padding-right: 50px;
}
