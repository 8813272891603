.itemHoverContainer {
  transition: background 0.3s ease, transform 0.5s ease;
}

.itemHover {
  background: url("https://res.cloudinary.com/tatwertech/image/upload/v1564399688/5_qbec9d.jpg");
  width: 100%;
  height: 100%;
  padding-top: 45%;
  transition: background 0.3s ease, transform 0.5s ease, padding-top 0.4s ease;
  cursor: crosshair;
  position: absolute;
}

.itemHoverContainerhover {
  height: 100%;
}

.itemHover p {
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px transparent solid;
  width: fit-content;
  transition: border-bottom 0.5s ease;
}

.itemHover p:hover {
  border-bottom: 1px black solid;
}

.itemHover:hover {
  background: #c2b59b;
}

.itemHover:hover .hid-content {
  transform: translateY(0%);
  opacity: 1;
}

.itemHover:hover {
  -webkit-animation: moveupanddown 1s forwards; /* for less modern browsers */
  animation: moveupanddown 1s ease forwards;
}

@keyframes moveupanddown {
  0% {
    padding-top: 45%;
  }
  50% {
    padding-top: 3%;
  }
  100% {
    padding-top: 6%;
  }
}

.title-shah {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 5px;
  border: none;
  background: #a97a4f;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1000px) {
  .itemHover .hid-content {
    transform: translateY(0%);
    opacity: 1;
  }
  .itemHover {
    background: #c2b59b;
    animation: moveupanddownmob 1s ease forwards;
    position: relative;
    height: fit-content;
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.hid-content {
  height: 100%;
  text-align: center;
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.2s ease;
  transform: translateY(100%);
}
