.documentItem {
  margin: 15px;
  -webkit-box-shadow: 2px -1px 5px 3px #74674c;
  -moz-box-shadow: 2px -1px 5px 3px #74674c;
  box-shadow: 2px -1px 5px 3px #74674c;
  padding: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: block;
  transition: transform 0.6s ease;
}

.documentItem:hover {
  transform: scale(1.05);
}
