* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "akaal", serif;
}

@font-face {
  font-family: akaal;
  src: url("./fonts/181125081623ass.woff");
}

body,
html {
  overflow: hidden;
}

.imghey {
  transition: opacity 0.4s ease;
}

.imghey:hover {
  opacity: 1 !important;
}

.css-df17o1 {
  position: fixed !important;
}

._loading_overlay_content {
  user-select: none;
}

*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.marginLeft {
  margin-left: 17%;
}

.mic-toolbox-all-credits {
  display: none;
}
#mic-init-access-tool .mic-access-tool-box.mic-access-tool-box-right {
  right: 15px;
  margin-top: 10px !important;
  margin-bottom: 10px;
  bottom: 15px;
}
#mic-init-access-tool .mic-access-tool-box {
  height: 100% !important;
  background: #8996cc;
}

.AnyClassForContainer {
  position: fixed;
  right: -100px;
  bottom: 60px !important;
  transition: right 0.5s;
  cursor: pointer;
  background-color: white;
  font-size: 20px;
  padding: 10px;
}

.AnyClassForTransition {
  right: 20px;
}

section {
  overflow: hidden;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 100%;
}

html,
body {
  overflow: visible;
  height: 100%;
}

#root {
  padding-bottom: 600px;
  position: relative;
  height: 100%;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

a {
  text-decoration: none;
}

a,
button,
li,
ul,
label,
input,
img {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

img {
  -webkit-user-drag: none;
}

.animated.delay-one-half {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.animated.delay-one-one {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.height35VH {
  height: 35vh;
}

.minHeight100 {
}

@media only screen and (max-width: 1000px) {
  .marginLeft {
    margin-left: unset;
  }
  .minHeight100 {
    min-height: unset;
    overflow: hidden;
  }

  .height35VH {
    height: 24vh;
  }

  .fixHead {
    position: fixed;
    z-index: 555555;
    background: white;
  }
  .fixHead2 {
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    z-index: 555555;
    background: white;
  }
  .fixHead img {
    margin-top: 10px;
    height: 80px !important;
  }
}
