button.greyButton {
  padding: 7px 40px;
  border: 2px transparent solid;
  transition: border-color 0.5s ease, background 0.6s ease, color 0.4s ease;
  cursor: pointer;
  background: rgb(220, 220, 220);
  font-size: 1.4rem;
  border-radius: 5px;
}

button.greyButton:hover {
  border-color: rgb(220, 220, 220);
  background: transparent;
}

button.purpleButton {
  padding: 7px 40px;
  border: 2px transparent solid;
  transition: border-color 0.5s ease, background 0.6s ease, color 0.4s ease;
  cursor: pointer;
  background: #a97a4f;
  font-size: 1.4rem;
  border-radius: 5px;
  color: white;
}

button.purpleButton:hover {
  border-color: #a97a4f;
  background: transparent;
  color: black;
}

button.blackButton {
  padding: 7px 40px;
  border: 2px transparent solid;
  transition: border-color 0.5s ease, background 0.6s ease, color 0.5s ease;
  cursor: pointer;
  background: black;
  color: white;
  font-size: 1.4rem;
  border-radius: 5px;
}

button.blackButton:hover {
  border-color: black;
  background: white !important;
  color: black;
}

.grey {
  padding: 5px 40px;
  border: 2px transparent solid;
  transition: border-color 0.5s ease, background 0.6s ease, color 0.4s ease;
  cursor: pointer;
  font-size: 1rem;
  background-color: #f2eee3;
  color: black;
  border-radius: 0;
}

.grey:hover,
.grey2:hover {
  background: #818286;
  color: white;
}

.arrowlef {
  opacity: 0;
  transition: opacity 0.7s ease-in opacity 0s ease-out;
  margin-top: 5px;
  margin-right: 10px;
}

.grey2 span {
  transition: transform 0.5s ease;
}

.grey2:hover .arrowlef {
  opacity: 1;
}
.grey2:hover span {
  transform: translate(20px);
}

.grey2 {
  padding: 5px 40px;
  border: 2px transparent solid;
  transition: background 0.6s ease, color 0.4s ease;
  cursor: pointer;
  font-size: 1rem;
  background-color: #c3b59b;
  color: black;
  border-radius: 0;
}

button.greyButton.color:hover {
  color: white;
}
