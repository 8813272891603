	/*
  	Flaticon icon font: Flaticon
  	Creation date: 30/07/2019 20:19
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-contract:before { content: "\f100"; }
.flaticon-team:before { content: "\f101"; }
.flaticon-urban:before { content: "\f102"; }
.flaticon-agreement:before { content: "\f103"; }
.flaticon-case:before { content: "\f104"; }
.flaticon-case-1:before { content: "\f105"; }
.flaticon-law:before { content: "\f106"; }
.flaticon-paper:before { content: "\f107"; }
.flaticon-draft:before { content: "\f108"; }
.flaticon-plane:before { content: "\f109"; }
.flaticon-fork:before { content: "\f10a"; }
.flaticon-monitor:before { content: "\f10b"; }
.flaticon-truck:before { content: "\f10c"; }
.flaticon-scissor:before { content: "\f10d"; }