.aboutUsSubContainer {
  margin-left: 120px;
  background-image: url("https://res.cloudinary.com/tatwertech/image/upload/v1564399222/2_hzeflh.jpg");
  box-shadow: 0px 4px 10px 2px rgba(200, 200, 200, 0.85);
  margin-bottom: 20px;
  padding: 25px;
  width: 330px;
  height: fit-content;
}

.aboutUsSubContainerEN {
  margin-right: 120px;
  background-image: url("https://res.cloudinary.com/tatwertech/image/upload/v1564399222/2_hzeflh.jpg");
  box-shadow: 0px 4px 10px 2px rgba(200, 200, 200, 0.85);
  margin-bottom: 20px;
  padding: 25px;
  width: 330px;
  height: fit-content;
}

@media only screen and (max-width: 1000px) {
  .aboutUsSubContainer,
  .aboutUsSubContainerEN {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 700px) {
  .aboutUsSubContainer,
  .aboutUsSubContainerEN {
    width: 100%;
  }
}
