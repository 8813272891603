.imageSlider img {
  height: 70vh;
  object-fit: cover;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.inline {
  display: inline;
}

.centerImg {
  filter: grayscale(100%);
  overflow: hidden;
  transition: margin-top 1.4s ease, transform 2.5s ease;
}

.centerImg:hover {
  margin-top: 55px;

  transform: scale(1.1);
}

.carousel.carousel-slider,
.carousel {
  position: inherit !important;
}

.carousel .slide {
  background: transparent !important;
}

.arrows,
.arrowsEN {
  position: relative;
}

.arrows .carousel.carousel-slider .control-prev.control-arrow {
  bottom: inherit;
  left: 0px;
  top: -35px;
  position: absolute;
  font-size: 20px;
  display: block !important;
  background: #a97a4f !important;
}

.arrows .carousel.carousel-slider .control-next.control-arrow {
  bottom: inherit;
  left: 30px;
  top: -35px;
  display: block !important;
  position: absolute;
  font-size: 20px;
  background: #a97a4f !important;
}
.arrowsEN .carousel.carousel-slider .control-prev.control-arrow {
  bottom: inherit;
  left: 70%;
  top: -35px;
  display: block !important;
  position: absolute;
  font-size: 20px;
  background: #a97a4f !important;
}

.arrowsEN .carousel.carousel-slider .control-next.control-arrow {
  bottom: inherit;
  right: 30px;
  top: -35px;
  display: block !important;
  position: absolute;
  font-size: 20px;
  background: #a97a4f !important;
}

.hey {
  position: relative;
}
.hey:before {
  content: " ";
  display: block;
  position: absolute;
  right: -10px;
  top: 35%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #e4e0d7;
}

.yours-custom-class {
  height: 50px;
  text-align: right;
  margin-right: auto !important;
  display: block;
}

.titleTeam {
  width: fit-content;
  direction: rtl;

  margin-bottom: 5px;
}

.containerCarousel {
  margin: 50px;
  font-size: 0.75rem;
}

.teamPicContainer {
  height: 300px;
  width: fit-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.invisContent {
  position: absolute;
  z-index: 5;
  height: 100%;
  opacity: 0;
  width: 100%;
  left: 0;
  transition: opacity 0.9s ease;
  right: 50%;
}

.SocialMediasContainer i {
  font-size: 1.5rem;
}

.teamPicContainer:hover .invisContent {
  opacity: 1;
}

.teamPicContainer:hover + .teamContent {
  background: #1d1e21;
  color: #c49569;
  margin-top: 0;
}

.teamPicContainer:after {
  position: absolute;
  content: " ";
  display: block;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  transition: opacity 0.7s ease;
  height: 100%;
  opacity: 0;
}

.moving {
  animation: moving 1.5s infinite;
  font-size: 1.6rem;
  margin-top: 67px;
  cursor: w-resize;
}

@keyframes moving {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-size: 5rem !important;
  display: block !important;
  margin-left: auto !important;
  width: fit-content !important;
  margin-right: auto !important;
}

.entry .title {
  float: right !important;
  width: unset !important;
}

.entry .body {
  margin: 0 0 3em !important;
  float: left !important;
  direction: rtl !important;
  width: unset !important;
  text-align: right !important;
}

.timeline-item-date {
  left: unset !important;
  right: 0px;
  clip-path: polygon(5% 0, 100% 0, 100% 100%, 5% 100%, 0 50%, 0 50%) !important;
  -webkit-clip-path-webkit-clip-path: polygon(
    5% 0,
    100% 0,
    100% 100%,
    5% 100%,
    0 50%,
    0 50%
  ) !important;
}

.timeline-item-dateinner {
  clip-path: polygon(5% 0, 100% 0, 100% 100%, 5% 100%, 0 50%, 0 50%) !important;
  -webkit-clip-path-webkit-clip-path: polygon(
    5% 0,
    100% 0,
    100% 100%,
    5% 100%,
    0 50%,
    0 50%
  ) !important;
}

.entry {
  display: grid !important;
  grid-gap: 50px 0px;
  grid-template-columns: 150px 20px 1fr !important;
}

.body-container {
  margin-left: unset !important;
}

.entry .title:before {
  left: 0px !important;
  right: unset !important;
  display: none;
}

.timeline:before {
  left: unset !important;
  display: none !important;
  right: calc(30% + 12px) !important;
}

.verticalLine {
  z-index: 9;
  padding-bottom: 30px;
  height: calc(100% + 30px) !important;
  width: 3px;
  background: #999;
}

.countupCount {
  font-size: 5rem;
}

.entry .title:after {
  content: " ";
  display: none;
  z-index: 9;
  top: 0;
  left: 5px;
  position: absolute;
  height: 100px;
  width: 3px;
  background: #999;
}

.timeline {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.teamPicContainer:hover:after {
  position: absolute;
  content: " ";
  display: block;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 100%;
  opacity: 0.55;
}

.teamPic {
  width: 250px;
  height: 300px;
  object-fit: cover;
}

.teamContent {
  background: grey;
  width: 250px;
  padding: 10px;
  margin-left: auto;
  border-radius: 0px 0px 10px 10px;
  transition: background 0.5s ease, color 0.45s ease;
  margin-right: auto;
}

.class {
  direction: rtl;
}
.heeey {
  transform: rotateY(10deg);
  position: relative;
  transition: transform 0.4s ease;
  cursor: crosshair;
  z-index: 1;
}

.heeey:before {
  content: " ";
  display: block;
  opacity: 0.35;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.6s ease;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  background-image: url(https://www.broylesco.com/site_images/hedge-fund-admin.jpg);
}

.heeey:hover {
  transform: rotateY(0deg);
}

.heeey:hover:before {
  opacity: 0.1;
}

.heeey1 {
  transform: rotateY(-10deg);
  position: relative;
  transition: transform 0.4s ease;
  cursor: crosshair;
  z-index: 1;
}
.heeey1:before {
  content: " ";
  display: block;
  opacity: 0.35;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 0.6s ease;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  background-image: url(http://lsib.co.uk/lms/wp-content/uploads/2017/03/LSIB-MBA.jpg);
}
.heeey1:hover {
  transform: rotateY(0deg);
}
.heeey1:hover:before {
  opacity: 0.1;
}

/* Make it move */
@keyframes marquee {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.imgbg:after {
  content: " ";
  background: white;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
}

.arrowmov {
  transition: transform 0.5s ease;
}

.arrowmov:hover {
  transform: translateY(10px);
}

.XOXOContainer {
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  direction: rtl;
  padding-top: 30px;
}

.XOXO {
  margin-right: 25%;
}

.XOXOContainerEN {
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  direction: ltr;
  padding-top: 30px;
}

.XOXOEN {
  margin-left: 25%;
}

.arrowmov {
  font-size: 2rem;
  cursor: pointer;
}

.lawSubContainer {
  margin-right: 15%;
}

.lawSubContainerEN {
  margin-left: 15%;
}

.header {
  transition: background 0.4s ease;
}

.Audits {
  margin-left: auto;
  margin-right: auto;

  padding-top: 20px;
  padding-bottom: 25px;
  background: url("https://res.cloudinary.com/tatwertech/image/upload/v1564399687/4_qoyjam.jpg");
  display: grid;
  grid-template-columns: 60% 40%;
  direction: rtl;
}

.AuditsEN {
  margin-left: auto;
  margin-right: auto;

  padding-top: 20px;
  padding-bottom: 25px;
  background: url("https://res.cloudinary.com/tatwertech/image/upload/v1564399687/4_qoyjam.jpg");
  display: grid;
  grid-template-columns: 60% 40%;
  direction: ltr;
}

.marginRight {
  margin-right: 20%;
}

.marginRightEN {
  margin-left: 20%;
}

.marginBottom {
  margin-bottom: 50px;
}

.ServicesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  position: relative;
  direction: rtl;
  overflow: hidden;
  min-height: 500px;
}

.accountantContainer {
  margin-left: auto;
  margin-right: auto;
  background: url("https://res.cloudinary.com/tatwertech/image/upload/v1564399687/4_qoyjam.jpg");
  display: grid;
  grid-template-columns: 60% 40%;
  direction: rtl;
}

.dateComponent {
  display: block;
  float: right;
  height: 100%;
  margin-left: auto;
  width: 130px;
  background: rgb(150, 150, 150);
  color: white;
  clip-path: polygon(7px 0, 100% 0, 100% 100%, 7px 100%, 0 50%, 0 50%);
}

.dateComponentEN {
  display: block;
  float: left;
  height: 100%;
  margin-left: auto;
  width: 130px;
  background: rgb(150, 150, 150);
  color: white;
  clip-path: polygon(7px 0, 100% 0, 100% 100%, 7px 100%, 0 50%, 0 50%);
}

.lawContainer {
  display: grid;
  padding-top: 15%;
  padding-top: 50px;
  grid-template-columns: 60% 40%;
  direction: rtl;
  background: url("https://res.cloudinary.com/tatwertech/image/upload/v1564399687/4_qoyjam.jpg");
}

.workingFieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 17px;
}

.marginLeft {
  margin-left: 17%;
}

.marginLeftEN {
  margin-right: 17%;
}

.reviewerImageContainer {
  width: 100px;
  height: 50px;
  margin: 15px;
}

@media only screen and (max-width: 1200px) and (min-width: 750px) {
  .marq {
    display: none;
  }
}

.reviewerImageContainer {
  width: 50px;
}

.paddingTop {
  padding-top: 15px;
}

.countUpTitle {
  font-size: 3rem;
}

.countUpParallex {
  display: grid;
  grid-template-columns: 1fr 10px 1fr;
  width: 50%;
  z-index: 4;
  text-align: center;
}

.marginLeftPx {
  margin-left: 120px;
}

.marginLeftPxEN {
  margin-right: 120px;
}

.parallaxSectorsContainer {
  display: flex;
  justify-content: center;
  position: relative;
  box-shadow: inset 0 10px 15px -10px white;
}
.parallaxSectorsContainer h1 {
  font-size: 3.65rem;
  text-align: center;
  margin-top: 35px;
  color: white;
}
.parallaxSectorsContainer h2 {
  text-align: center;
  margin-top: 35px;
  color: white;
  margin-right: 15%;
  margin-left: 15%;
  z-index: 5;
  direction: rtl;
}

.marignTopNaks {
  margin-top: 67px;
}

.paddingPx {
  padding: 30px;
}

.sectorsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  margin-top: 100px;
}

.advantagesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  direction: rtl;
  height: 100%;
  position: relative;
}

.advantagesSubContainer {
  margin-top: 25px;
  margin-bottom: 25px;

  background: rgb(255, 255, 255, 0.8);
  padding: 25px;
}

.imgbg img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-top: -150px;
  object-fit: scale-down;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .marginTopNak {
    text-align: center;
  }
  .marginTopNak div {
    margin-left: auto;
    margin-right: auto;
  }
  .sectorsContainer {
    margin-top: 15px;
  }
  .lawSubContainer {
    margin-right: 0px;
  }
  .lawSubContainerEN {
    margin-left: 0px;
  }
  .imgbg {
    height: -webkit-fill-available;
    position: relative;
    object-fit: cover;
  }
  .imagebgContainer {
    height: 100%;
  }
  .imgbg img {
    object-fit: cover;
    width: 100%;
    height: -webkit-fill-available;
    margin-top: 0;
  }
  .XOXO {
    width: fit-content;
    margin-right: 20px;
  }

  .XOXOEN {
    margin-left: 20px;
    margin-right: 20px;
    width: fit-content;
  }
  .advantagesSubContainer {
    padding: 15px;
    margin: 0px !important;
  }
  .advantagesContainer {
    display: block;
  }
  .dateComponent,
  .dateComponentEN {
    width: 100px;
  }

  .workingFieldsContainer {
    display: block;
  }
  .marignTopNaks {
    margin-top: unset;
  }
  .paddingPx {
    padding: 15px;
  }
  .parallaxSectorsContainer h2 {
    margin-top: 0px;
  }
  .sectorsContainer {
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
  }
  .lawContainer {
    display: block;
    padding-top: 5px;
    padding-right: 0;
  }
  .parallaxSectorsContainer {
    display: block;
  }
  .parallaxSectorsContainer h1 {
    font-size: 2rem;
  }
  .parallaxSectorsContainer h2 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .timeline--wrapper {
    padding: 0px !important;
  }
  .countUpParallex {
    width: 80%;
  }
  .countupCount {
    font-size: 2.5rem;
  }
  .marginLeftPx {
    margin-left: 20px;
  }
  .marginLeftPxEN {
    margin-right: 20px;
  }
  .countUpTitle {
    font-size: 1.75rem;
  }
  .XOXO h1 {
    font-size: 1.7rem;
  }
  .XOXO h3 {
    margin-left: 15px;
  }
  .ServicesContainer,
  .accountantContainer,
  .XOXOContainer,
  .XOXOContainerEN {
    display: block;
  }
  .marginBottom {
    margin-bottom: 25px;
  }
  .entry {
    grid-template-columns: 110px 20px 1fr !important;
  }

  .timeline--wrapper {
    width: 100% !important;
  }

  .moving,
  .arrowmov,
  .blackButton,
  .marq {
    display: none;
  }
  .Audits,
  .AuditsEN {
    display: block;
  }
  .marginRight {
    margin-right: 20px;
    margin-left: 20px;
  }
  .marginRightEN {
    margin-right: 20px;
    margin-left: 20px;
  }
  .marginLeftEN {
    margin: 0;
  }
}
