.blogPostContent:hover {
  opacity: 0.9;
}

.blogPostContent {
  align-self: center;
  text-align: center;
  opacity: 0.8;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  direction: rtl;
  transition: opacity 0.4s ease;
}

.blogPostContent p {
  margin-top: 10px;
}

.blogPostContent h1 {
  color: black;
}

.blogPost1 img {
  object-fit: contain;
  width: 100%;
  height: 200px;
  opacity: 0.9;
  border-radius: 5px;
  transition: transform 0.5s ease, opacity 0.3s ease;
}

.width50Pr {
  width: 50%;
}

.blogPost1 img:hover {
  transform: scale(1.05);
  opacity: 1;
}

.blogPost1 {
  width: 70%;
  padding: 20px;
  background: url("https://res.cloudinary.com/tatwertech/image/upload/v1564399222/2_hzeflh.jpg");
  position: relative;
  box-shadow: 0px 4px 10px 2px rgba(150, 150, 150, 0.85);
  border-radius: 5px;
  margin-bottom: 10px;
}

.blogPostGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 1000px) {
  .blogPost1 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .width50Pr {
    width: 100%;
  }
  .blogPostGrid {
    display: block;
  }
  .blogPostContent {
    margin-top: 10px;
  }
}
