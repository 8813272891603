.marq {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  background: black;
}
.marq p {
  padding-left: 100%;
  padding: 5px;
  letter-spacing: 2px;
  color: white;
  cursor: text;
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  text-align: right;
  white-space: nowrap;
  /* animation: marquee 20s linear infinite; */
  margin: 0;
}

@media only screen and (max-width: 1200px) {
  .marq {
    display: none;
  }
}
