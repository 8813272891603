.gridFr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  direction: rtl;
}

.aboutUsContainer {
  padding-top: 25px;
  display: grid;
  grid-template-columns: 330px 1fr;
  text-align: right;
  direction: rtl;
  padding-right: 7%;
}

.ContactContainerWidget {
  box-shadow: 0px 4px 10px 2px rgba(200, 200, 200, 0.85);
  text-align: center;
  margin-top: 25px;
  margin-right: 25px;
  padding: 25px;
}

.width330 {
  width: 330px;
}

@media only screen and (max-width: 1000px) {
  .gridFr {
    display: block;
  }
  .aboutUsContainer {
    flex-direction: column-reverse;
    padding-right: 0%;
    display: flex;
  }
  .ContactContainerWidget {
    margin-right: 0px;
    width: 100%;
  }
  .width330 {
    width: 100%;
  }
}
