.lawServicesContainer {
  display: grid;
  grid-template-columns: 350px 1fr;
  direction: rtl;
  padding-right: 7%;
}

.lawServicesContainerEN {
  display: grid;
  grid-template-columns: 350px 1fr;
  direction: ltr;
  padding-left: 7%;
}

@media only screen and (max-width: 1000px) {
  .lawServicesContainer {
    padding-right: 0px;
    flex-direction: column-reverse;
    display: flex;
  }
  .lawServicesContainer {
    padding-left: 0px;
    flex-direction: column-reverse;
    display: flex;
  }
}
