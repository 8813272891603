.shown-par {
  position: relative;
  transition: transform 0.5s ease;
}

.hidden-par:hover {
  transform: scale(1.05);
  top: 10px;
}

.hidden-par {
  transition: transform 0.5s ease;
}
