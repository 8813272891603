@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;

  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"),
    url("./fonts/slick.woff") format("woff"),
    url("./fonts/slick.ttf") format("truetype"),
    url("./fonts/slick.svg#slick") format("svg");
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;
  z-index: 50;
  display: block;

  width: 60px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  /* color: transparent;
  outline: none;
  background: transparent; */
  opacity: 0.8;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
} /*
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
} */

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 30px;
  line-height: 1;

  padding: 0;
  opacity: 1;
  color: rgb(196, 149, 105);
  transition: all 0.6s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.new .slick-prev {
  left: 10%;
}

.new .slick-next {
  right: 10%;
}

.slick-prev {
  left: 0px;
  height: 100%;
  background: white;
  opacity: 0.35;
  transition: opacity 0.3s ease;
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
  box-shadow: 3px 3px 10px rgb(196, 149, 105, 0.6);
}
[dir="rtl"] .slick-prev {
  right: 1px;
  left: auto;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 0px;
  background: white;
  opacity: 0.35;
  height: 100%;
  transition: opacity 0.3s ease;
  border-top-left-radius: 500px;
  border-bottom-left-radius: 500px;
  box-shadow: 3px 3px 10px rgb(196, 149, 105, 0.6);
}
[dir="rtl"] .slick-next {
  right: auto;
  left: 0;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */

.new .slick-dots {
  position: absolute;
  bottom: 8px;
  left: 10%;
  display: block;
  z-index: 80;
  width: fit-content;
  background: rgb(222, 222, 222);
  padding: 0;

  list-style: none;
}

.slick-dots {
  position: absolute;
  bottom: -50px;
  display: block;
  z-index: 80;
  width: 100%;
  padding: 10px;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 30px;
  height: 20px;
  margin: 0 10px;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 30px;
  height: 4px;
  background-color: rgb(196, 149, 105, 0.2);

  cursor: pointer;
  border: 0;
  outline: none;
  transition: background-color 0.5s ease;
}

.slick-dots li button:hover {
  background-color: #eee;
}
.slick-dots li button:hover {
  background-color: #eee;
}
/* .slick-dots li button:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */
.slick-dots li.slick-active button {
  background-color: rgb(196, 149, 105);
}
