.documentsContainer {
  display: grid;
  grid-template-columns: 350px 1fr;
  direction: rtl;
  padding-right: 7%;
}

.documentsContainerEN {
  display: grid;
  grid-template-columns: 350px 1fr;
  direction: ltr;
  padding-left: 7%;
}

.linksContainer {
  display: grid;
  padding: 15px;
  margin-left: 5%;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(1rem, 1fr));
}

.marginRight5Pr {
  margin-right: 5%;
}
.marginLeft5Pr {
  margin-left: 5%;
}

@media only screen and (max-width: 1000px) {
  .documentsContainer {
    padding-right: 0px;
    flex-direction: column-reverse;
    display: flex;
  }
  .documentsContainerEN {
    padding-left: 0px;
    flex-direction: column-reverse;
    display: flex;
  }
  .marginRight5Pr {
    margin-right: 0%;
  }
  .marginLeft5Pr {
    margin-left: 0%;
  }
}
