.specializedItem {
  cursor: pointer;
  transition: background 0.5s ease;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  width: 200px;
  margin-bottom: 50px;
}

.specializedItem:hover {
  background: rgb(0, 0, 255, 0.5);
}

.specializedItemImage {
  width: 100%;
  margin-top: 30px;
}

@media only screen and (max-width: 1000px) {
  .specializedItem {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: unset;
  }

  .specializedItemImage {
    margin-top: 5px;
    padding-top: 5px;
  }
}
