.bl:after {
  content: " ";
  width: 60px;
  display: inline-block;
  height: 8px;
  background: rgb(129, 0, 101, 0.2);
  margin-left: auto;
  margin-right: auto;
}

.bl:before {
  content: " ";
  width: 60px;
  display: inline-block;
  height: 8px;
  background: rgb(129, 0, 101);
  margin-left: auto;
  margin-right: auto;
}

.accountingservice {
  display: grid;
  grid-template-columns: 350px 1fr;
  direction: rtl;
  padding-right: 7%;
}

.accountingserviceEN {
  display: grid;
  grid-template-columns: 350px 1fr;
  direction: ltr;
  padding-left: 7%;
}

@media only screen and (max-width: 1000px) {
  .accountingservice {
    padding-right: 0px;
    flex-direction: column-reverse;
    display: flex;
  }
  .accountingserviceEN {
    padding-left: 0px;
  }
}
