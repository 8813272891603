footer {
  height: 775px;
  padding-top: 100px;
  position: relative;
  overflow: hidden;
  color: black;

  position: absolute;
  width: 100%;
  /* background: linear-gradient(#810065, transparent),
    linear-gradient(to top left, red, transparent),
    linear-gradient(to top right, #001fff, transparent);
  background-blend-mode: exclusion; */
}

.halfIcon {
  animation: 2s shaking ease infinite;
  cursor: crosshair;
}

.marginTop65 {
  margin-top: 65px;
}

@keyframes shaking {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(1px);
  }
  50% {
    transform: translateY(-1px);
  }
  75% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0px);
  }
}

footer .fancy {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  padding-right: 10vw;
  padding-left: 10vw;
}
footer .footerTitle {
  overflow: hidden;
  font-size: 1.75rem;
  text-align: center;
}

footer .center {
  position: relative;
  justify-content: center;
  display: flex;
}
footer .column {
  margin-top: 4%;
  margin-left: auto;
  margin-right: auto;
  width: 2px;
  height: 86px;
  background: #7c4889;
  opacity: 0.8;
}

footer ul li {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

footer ul li a h2 {
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

footer .contactTxt {
  opacity: 0.8;
  position: absolute;
  text-transform: uppercase;
}

.socialMedias {
  font-size: 1.5rem;
  margin-top: 1.5%;
}

.socialMedias i {
  margin-right: 5px;
  color: #4f266a;
  margin-left: 5px;
}

.socialMedias i:hover {
  color: black;
  transition: all 0.7s ease;
}

footer .footerFirstColumn,
footer .footerThirdColumn {
  text-align: center;
}

footer .footerFirstColumn li,
footer .footerThirdColumn li {
  list-style: none;
}

.footerNewsletter {
  display: flex;
  margin-left: 20px;
  margin-top: 30px;
  align-items: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  flex-direction: column;
}

.footerNewsletter button {
  margin-top: -11px;
  text-transform: uppercase;
  cursor: pointer;
  background: linear-gradient(to right, white 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  width: 200px;
  color: #ddd;
  transition: all 0.4s ease;
  border: 1px solid white;
}

.footerNewsletter button:hover {
  background-position: left bottom;
}

footer .column p {
  padding-left: 13px;
  padding-top: 10px;
  width: max-content;
}

.footerNewsletter .text {
  text-align: center;
  font-size: 1.25rem;
  padding: 13px;

  line-height: 30px;
  color: black;
  transition: all 0.4s ease;
  display: block;
}

.footerNewsletter .text:hover {
  color: black;
}

.footerNewsletter input {
  margin: 10px;
  font-size: 1.25rem;
  width: 200px;
  color: black;
  text-align: center;
  padding: 13px;
  background: transparent;
  border: 1px solid white;
}

.footer-up {
  height: 360px;
  background-image: url("https://res.cloudinary.com/tatwertech/image/upload/v1564392629/footerbg_1_h9wom5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.footerNewsletter input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

.footerNewsletter input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

footer iframe {
  height: 100%;
}

.footerNewsletter input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

.footer-copyright {
  display: flex;
  justify-content: space-around;
  padding: 15px;
  color: #312d2c;
  background: #c2b59b;
}

@media screen and (max-width: 1000px) {
  footer .footerTitle {
    font-size: 1.15rem;
  }

  .marginTop65 {
    margin-top: 20px;
  }
  .footer-copyright {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }
  .wazeContainer {
    margin-bottom: 50px;
  }
  .dontShowOnMobile {
    display: none;
  }
  .footer-copyright h2 {
    padding: 15px 0px;
  }
  .halfIcon {
    display: none;
  }
  footer {
    height: unset;
    padding-top: 0px;
  }

  .footer-up {
    display: block;
    height: unset;
    padding-bottom: 25px;
    padding-top: 5px;
  }
  footer .fancy {
    padding-right: 3vw;
    padding-left: 3vw;
    display: block;
  }
  footer .footerTitle:before {
    right: 0.5em;
    margin-left: -50%;
  }
  footer .footerTitle:after {
    left: 0.5em;
    margin-right: -50%;
  }
  .socialMedias i {
    margin-left: 10px;
  }
  .socialMedias {
    font-size: 1rem;
    margin-top: 2.5%;
  }
}
