@media only screen and (max-width: 1200px) and (min-width: 750px) {
  .marq {
    display: none;
  }
}

.reviewerImageContainer {
  width: 50px;
}

.paddingTop {
  padding-top: 15px;
}

.countUpTitle {
  font-size: 3rem;
}

.countUpParallex {
  display: grid;
  grid-template-columns: 1fr 10px 1fr;
  width: 50%;
  z-index: 4;
  text-align: center;
}

.marginLeftPx {
  margin-left: 120px;
}

.parallaxSectorsContainer {
  display: flex;
  justify-content: center;
  position: relative;
  box-shadow: inset 0 10px 15px -10px white;
}

.aboutUsContainer {
  display: grid;
  grid-template-columns: 350px 1fr !important;
  direction: rtl;
  padding-right: 7%;
}

.aboutUsContainerEN {
  display: grid;
  grid-template-columns: 350px 1fr !important;
  direction: ltr;
  padding-left: 7%;
}

.parallaxSectorsContainer h1 {
  font-size: 3.65rem;
  text-align: center;
  margin-top: 35px;
  color: white;
}
.parallaxSectorsContainer h2 {
  text-align: center;
  margin-top: 35px;
  color: white;
  margin-right: 15%;
  margin-left: 15%;
  z-index: 5;
  direction: rtl;
}

.marignTopNaks {
  margin-top: 67px;
}

.paddingPx {
  padding: 30px;
}

.sectorsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  margin-top: 100px;
}

.advantagesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  direction: rtl;
  height: 100%;
  position: relative;
}

.advantagesSubContainer {
  margin-top: 25px;
  margin-bottom: 25px;
  margin-right: 25%;
  background: rgb(255, 255, 255, 0.8);
  padding: 25px;
}

.imgbg img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-top: -150px;
  object-fit: scale-down;
  position: relative;
}

.marginLeftPercent {
  margin-left: 17%;
}

.marginLeftPercentEN {
  margin-right: 17%;
}

@media only screen and (max-width: 1000px) {
  .marginLeftPercent {
    margin-left: 0px;
  }
  .marginLeftPercentEN {
    margin-right: 0px;
  }
  .sectorsContainer {
    margin-top: 15px;
  }
  .aboutUsContainer {
    padding-right: 0px;
    flex-direction: column-reverse;

    display: flex;
  }
  .aboutUsContainerEN {
    padding-left: 0px;
    flex-direction: column-reverse;

    display: flex;
  }

  .imgbg {
    height: -webkit-fill-available;
    position: relative;
    object-fit: cover;
  }
  .imagebgContainer {
    height: 100%;
  }
  .imgbg img {
    object-fit: cover;
    width: 100%;
    height: -webkit-fill-available;
    margin-top: 0;
  }
  .XOXO {
    width: fit-content;
    margin-right: 20px;
  }
  .advantagesSubContainer {
    padding: 15px;
    margin: 0px;
  }
  .advantagesContainer {
    display: block;
  }
  .dateComponent {
    width: 100px;
  }
  .workingFieldsContainer {
    display: block;
  }
  .marignTopNaks {
    margin-top: unset;
  }
  .paddingPx {
    padding: 15px;
  }
  .parallaxSectorsContainer h2 {
    margin-top: 0px;
  }
  .sectorsContainer {
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
  }
  .lawContainer {
    display: block;
    padding-top: 5px;
    padding-right: 0;
  }
  .parallaxSectorsContainer {
    display: block;
  }
  .parallaxSectorsContainer h1 {
    font-size: 2rem;
  }
  .parallaxSectorsContainer h2 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .timeline--wrapper {
    padding: 0px !important;
  }
  .countUpParallex {
    width: 80%;
  }
  .countupCount {
    font-size: 2.5rem;
  }
  .marginLeftPx {
    margin-left: 20px;
  }
  .countUpTitle {
    font-size: 1.75rem;
  }
  .XOXO h1 {
    font-size: 1.7rem;
  }
  .XOXO h3 {
    margin-left: 15px;
  }
  .ServicesContainer,
  .accountantContainer,
  .XOXOContainer {
    display: block;
  }
  .marginBottom {
    margin-bottom: 25px;
  }
  .entry {
    grid-template-columns: 110px 20px 1fr !important;
  }

  .timeline--wrapper {
    width: 100% !important;
  }

  .moving,
  .arrowmov,
  .blackButton,
  .marq {
    display: none;
  }
  .Audits {
    display: block;
  }
  .marginRight {
    margin-right: 20px;
    margin-left: 20px;
  }
  .marginLeft {
    margin-left: unset;
  }
}
