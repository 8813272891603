.teamPlayerPic {
  background: blue;
}
.teamPlayerPic:hover {
  background: blue;
}

.teamPlayerContainer {
  display: grid;
  grid-template-columns: 150px 1fr;
  direction: rtl;
}

.padding25 {
  padding: 25px;
}

@media only screen and (max-width: 700px) {
  .teamPlayerContainer {
    display: block;
  }
  .padding25 {
    padding: 0px !important;
    padding-right: 25% !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .paddingleftandright {
    padding-right: 25% !important;
  }
}
