@media screen and (min-width: 1000px) {
  .dropdown {
    display: inline-block;
    position: relative;
  }

  .effect-3:before {
    right: 0;
    bottom: -5px;
    transition: width 0.4s ease;
    background: #a97a4f;
  }
  .effect-3:before {
    width: 0%;
    height: 2px;
  }

  .effect-3:hover:before,
  .effect-3:hover:after,
  .effect-3:hover > span:before,
  .effect-3:hover > span:after {
    width: 100%;
  }

  .dropdown .dropdown-content {
    position: absolute;
    background-color: #a97a4f;
    min-width: 160px;
    top: 40px;
    z-index: 5;
    cursor: default;
    visibility: hidden;
    opacity: 0;
    padding: 30px 25px 35px;
    box-shadow: 2px 2px 2px 0px #a97a4f;
    transition: visibility 0.2s ease, opacity 0.7s ease;
  }

  .dropdown-content p {
    color: white;
    width: max-content;
    text-decoration: none;
    font-size: 0.9rem;
    cursor: pointer;
    padding: 4.5px;
    margin-left: auto;
    font-weight: 600;
    display: block;
    transition: color 0.5s ease;
  }

  .dropdown-content p:hover {
    color: black;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content:hover {
    display: block;
    visibility: visible;
    opacity: 0.99;
    pointer-events: all;
  }
}

@media screen and (max-width: 1000px) {
  .dropdown-content {
    transition: opacity 0.5s ease;
  }
  .dropbtn,
  .effect-5 > span {
    display: inline-block;
  }
  .fas.fa-arrow-alt-circle-down {
    display: inline-block;
  }
  .dropdown-content p {
    text-align: center;
    padding: 2.5px;
    color: #666;
    transition: color 0.3s ease;
  }
  .dropdown-content p:active {
    color: #810065;
  }
}
