.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.board-row {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.square[type="0"],
.square[type="1"],
.square[type="2"],
.square[type="3"],
.square[type="4"],
.square[type="5"] {
  border-bottom: 3px black solid;
}

.square[type="1"],
.square[type="4"],
.square[type="7"] {
  border-right: 3px black solid;
  border-left: 3px black solid;
}

.square {
  border: 1px solid transparent;
  float: left;
  cursor: pointer;
  font-size: 50px;
  font-weight: bold;
  line-height: 120px;
  height: 120px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  background: transparent;
  text-align: center;
  width: 120px;
  outline: none;
}

.game {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 410px) {
  .square {
    height: 80px;
    width: 80px;
    line-height: 80px;
  }
}
