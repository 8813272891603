.logo img {
  height: 128px;
  cursor: pointer;
  transition: height 1s ease;
}

.shine {
  position: relative;
  overflow: hidden;
}

.shine:after {
  content: " ";
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  -webkit-animation: slide 2.3s infinite;
  animation: slide 2.3s infinite;

  /* 
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
  */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(
    to right,
    rgb(249, 249, 245, 0) 0%,
    rgb(249, 249, 245, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}
@-webkit-keyframes slide {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

@keyframes slide {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

.logo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  height: 125px;
  transition: height 0.75s ease;
}
.fa-arrow-alt-circle-down:before {
  font-size: 1.75rem;
  vertical-align: middle;
}

.mobile-navBar {
  cursor: pointer;
  display: none;
}

.mobile-navBar div {
  width: 23px;
  height: 3px;
  background-color: #a97a4f;
  margin: 5px;
  transition: all 0.3s ease;
}

.nav {
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top .nav-links a {
  color: black;
}
.nav-links {
  display: flex;
  padding: 5px;
  justify-content: space-around;

  direction: rtl;
  width: 90%;
}

.nav-links li {
  list-style: none;
}

.en {
  direction: ltr !important;
}

.nav-links a {
  color: black;
  width: fit-content;
  letter-spacing: 1.2px;
  transition: opacity 0.4s ease, border 0.4s ease, color 0.5s ease;
  cursor: pointer;
}

.nav-links li {
  margin-top: auto;
  margin-bottom: auto;
}

.logo a {
  float: left;
}
.fas.fa-arrow-alt-circle-down {
  display: none;
}
@media screen and (min-width: 1025px) {
  .logo img {
    margin-top: 25px;
  }
  .logo.top img {
    height: 90px;
  }

  .logo.top {
    height: 95px;
  }
}

@media screen and (max-width: 1015px) {
  .logo-in-navfixed {
    display: none !important;
  }

  .mobile-hover.effect-5 > span:before,
  .mobile-hover.effect-5 > span:after {
    left: 0;
    top: 0;
    background: transparent;
  }
  .mobile-hover.effect-5:before,
  .mobile-hover.effect-5:after {
    right: 0;
    bottom: 0;
    background: transparent;
  }
  .nav-links {
    position: absolute;
    right: 0px;
    min-height: 50vh;
    top: 100%;
    font-size: 1.1rem;
    display: flex;
    justify-content: flex-start;
    background-color: black;
    opacity: 0.95;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
  }
  .logo {
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 100vw;
  }
  .logo a {
    float: none;
  }
  .logo img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .nav {
    margin-top: 0;
    transition: background 0.4s ease;
  }
  .logo img {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .nav-links a span {
    color: white;
  }
  .nav-links li {
    opacity: 0;
    width: 80%;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .mobile-navBar {
    display: block;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 1001px) {
  .header {
    height: 35vh;
  }
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 7px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.effect-5 > span {
  display: block;
  font-size: 1rem;
  padding: 5px;
}

.effect-5 {
  display: flex;
  position: relative;
  overflow: hidden;
}
.effect-5:before,
.effect-5:after {
  right: 0;
  bottom: 0;
  background: #a97a4f;
}
.effect-5:before {
  width: 100%;
  height: 2px;
  transform: translateX(-100%);
}
.effect-5:after {
  width: 2px;
  height: 100%;
  transform: translateY(100%);
}
.effect-5 > span:before,
.effect-5 > span:after {
  left: 0;
  top: 0;
  background: #a97a4f;
}
.effect-5 > span:before {
  width: 100%;
  height: 2px;
  transform: translateX(100%);
}
.effect-5 > span:after {
  width: 2px;
  height: 100%;
  transform: translateY(-100%);
}
.effect-5:hover:before,
.effect-5:hover:after,
.effect-5:hover > span:before,
.effect-5:hover > span:after {
  transform: translate(0, 0);
}
a:before,
a:after,
a > span:before,
a > span:after {
  content: " ";
  position: absolute;
  transition: transform 0.5s ease;
}

a,
a > span {
  position: relative;
  color: inherit;
  text-decoration: none;
}
