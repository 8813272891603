.titleTeam1 {
  width: fit-content;
  cursor: ew-resize;
}

.titleTeam1:hover:before {
  background: grey;
}

.titleTeam1:hover:after {
  background: #c49569;
}

.titleTeam1:before {
  content: " ";
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  background: #c49569;
  transition: background 1s ease;
  width: 50px;
  height: 7px;
}
.titleTeam1:after {
  content: " ";
  display: inline-block;
  background: grey;
  margin-left: auto;
  transition: background 1s ease;
  margin-right: auto;
  width: 50px;
  height: 7px;
}
